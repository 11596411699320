<template>
  <div class="w-full mb-4 bg-white rounded-xl shadow-lg text-gray-600">
    <div
      class="px-6 py-2 rounded-lg flex justify-between items-center cursor-pointer"
      @click="toggleAccordion"
      style="border-bottom: gray solid 1px"
    >
      <p class="text-dark text-left">
        <b>Respuesta por parte de la entidad vigilada</b>
      </p>
      <span
        class="h-5 w-5 m-2 flex justify-center items-center transition"
        :class="{ 'transform rotate-180': accordionOpen }"
      >
        <img src="@/assets/icons/arrow-down.svg" alt="" />
      </span>
    </div>
    <div v-if="accordionOpen" class="px-6 py-3 pb-6">       
      <div class="flex flex-row gap-2">
        <div class="w-full grid grid-cols-2 gap-6">
          <BaseInput
            type="text"
            label="Fecha de cierre"
            v-model="complaint.fecha_cierre"
            placeholder=""
            :disabled="true"
          />
          <BaseInput
            type="text"
            label="Favorabilidad de cierre"
            v-model="complaint.a_favor_de"
            placeholder=""
            :disabled="true"
          />
        </div>
      </div>
      <div class="w-full grid grid-cols-1 gap-2">
        <BaseInput
          type="text"
          label="Aceptación"
          v-model="complaint.aceptacion_queja"
          placeholder=""
          :disabled="true"
        />
      </div>
      <div class="w-full grid grid-cols-1 gap-2">
        <BaseInput
          type="text"
          label="Retificación"
          v-model="complaint.rectificacion_queja"
          placeholder=""
          :disabled="true"
        />
      </div>
      <div class="flex flex-row gap-2">
        <div class="w-full grid grid-cols-2 gap-6">
          <BaseInput
            type="text"
            label="Desistimiento"
            v-model="complaint.desistimiento_nombre"
            placeholder=""
            :disabled="true"
          />
          <BaseInput
            type="text"
            label="Admisión"
            v-model="complaint.admision"
            placeholder=""
            :disabled="true"
          />
        </div>
      </div>
      <div class="w-full grid grid-cols-1 gap-2">
        <BaseInput
          type="text"
          label="Marcación"
          v-model="complaint.marcacion"
          placeholder=""
          :disabled="true"
        />
      </div>
      <div class="flex flex-row gap-2">
        <div class="w-full grid grid-cols-2 gap-6">
          <BaseInput
            type="text"
            label="Tutela"
            v-model="complaint.tutela_nombre"
            placeholder=""
            :disabled="true"
          />
          <BaseInput
            type="text"
            label="Queja exprés"
            v-model="complaint.expres"
            placeholder=""
            :disabled="true"
          />
        </div>
      </div>
      <div class="flex flex-row gap-2">
        <div class="w-full grid grid-cols-2 gap-6">
          <BaseInput
            type="text"
            label="Instancia de recepción"
            v-model="complaint.insta_recepcion"
            placeholder=""
            :disabled="true"
          />
          <BaseInput
            type="text"
            label="Punto de recepción"
            v-model="complaint.punto_recepcion"
            placeholder=""
            :disabled="true"
          />
        </div>
      </div>
      <div class="flex flex-row gap-2">
        <div class="w-full grid grid-cols-1 gap-2">
          <BaseInput
            type="text"
            label="Documento de respuesta final"
            v-model="complaint.documentacion_final"
            placeholder=""
            :disabled="true"
          />
        </div>
      </div>
<!-------------------------------------------------------------management------------------------------------------------------------->
      <div
        v-if="respuesta.count > 0 || respuesta.loading"
      >
        <a-divider type="horizontal" class="mt-8" />

        <h1 class="text-left font-bold text-gray-500">Documentos de gestión</h1>
        <div v-if="respuesta.loading" class="flex justify-center items-center">
          <a-spin>
            <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
          </a-spin>
        </div>
        <div class="w-full grid grid-cols-1 gap-2">
          <BaseDownload
            v-for="(option, index) in respuesta.attachments"
            label="Anexo de la respuesta final"
            :value="option.name_file"
            :key="index"
            :url_download="option.id_storage + separator"
          />
        </div>
        <div class="p-4 flex justify-end"
          v-if="respuesta.count >= 10"><!-- 10: minimum number of attachments per page -->
          <a-pagination
            v-model="respuesta.page"
            :page-size="respuesta.pageSize"
            :page-size-options="pageSizeOptions"
            :total="respuesta.count"
            show-size-changer
            size="small"
            @showSizeChange="onShowSizeChange"
          />
        </div>
      </div>
<!-------------------------------------------------------------answer------------------------------------------------------------->
      <div
        v-if="respuesta_final.count > 0 || respuesta_final.loading"
      >
        <a-divider type="horizontal" class="mt-8" />

        <h1 class="text-left font-bold text-gray-500">
          Documentos de respuesta final
        </h1>
        <div v-if="respuesta_final.loading" class="flex justify-center items-center">
          <a-spin>
            <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
          </a-spin>
        </div>
        <div class="w-full grid grid-cols-1 gap-2">
          <BaseDownload
            v-for="(option, index) in respuesta_final.attachments"
            label="Anexo de la respuesta final"
            :value="option.name_file"
            :key="index"
            :url_download="option.id_storage + separator"
          />
        </div>
        <div class="p-4 flex justify-end"
          v-if="respuesta_final.count >= 10"><!-- 10: minimum number of attachments per page -->
          <a-pagination
            v-model="respuesta_final.page"
            :page-size="respuesta_final.pageSize"
            :page-size-options="pageSizeOptions"
            :total="respuesta_final.count"
            show-size-changer
            size="small"
            @showSizeChange="onShowSizeChangeFinal"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["complaint"],
  data() {
    return {
      respuesta_final:{
        count:0,
        page: 1,
        pageSize: 10,
        loading: true,
        attachments: [], 
        reference: 7,
        ids: null
      },
      respuesta:{
        count:0,
        page: 1,
        pageSize: 10,
        loading: true,
        attachments: [], 
        reference: 6,
        ids: null
      },
      pageSizeOptions: ["10", "20", "50"],
      accordionOpen: false, 
      pre_search: false,
      separator: '_id_storage__SFC',
      created_at: null
    };
  },
  created(){
    if(Object.keys(this.complaint).includes("created_at")){
      this.created_at = this.complaint.created_at;
    }
  },
  watch: {
    'respuesta_final.page': function (newVal, oldVal) {
      this.fetchAnnexes(this.respuesta_final);
    },
    'respuesta.page': function (newVal, oldVal) {
      this.fetchAnnexes(this.respuesta);
    },
  },
  methods: {
    onShowSizeChange(page, page_size) {
      let field = this.respuesta;
      field.pageSize = page_size;
      if(page == field.page){
        field.page = page;
        this.fetchAnnexes(field)
      }
    },
    onShowSizeChangeFinal(page, page_size) {
      let field = this.respuesta_final;
      field.pageSize = page_size;
      if(page == field.page){
        field.page = page;
        this.fetchAnnexes(field)
      }
    },
    toggleAccordion() {
      if (!this.pre_search){
        this.fetchAnnexes(this.respuesta_final);
        this.fetchAnnexes(this.respuesta);
        this.pre_search = !this.pre_search;
      }
      this.accordionOpen = !this.accordionOpen;
    },
    async getUrlFile(key){
      console.log(key);
      return key;
    },
    async fetchAnnexes(field){
      field.loading = true;
      field.attachments = [];
      
      let query = `?page_size=${field.pageSize}&page=${field.page}&codigo_queja=${this.complaint.codigo_queja}&reference_storage=${field.reference}`;

      if(this.created_at){
        query += `&created_at__lte=${this.created_at}&get_from_bq=${true}`
      }

      let { error, data } = await this.$api.getAttachmentsAll(query);
      

      if (error) this.legend = "Ocurrió un error con la consulta";
      
      if (data) {
        field.attachments = data.results;
        field.count = data.total;
      }
      
      field.loading = false;

      if(field.reference == 7){
        this.respuesta_final = field;
      }

      return field;
    },
  },
};
</script>