<template>
    <div class="w-full py-2 rounded-xl border-blue-dark border-2 flex flex-col sm:flex-row justify-around items-center">
        <div class="w-full py-2">
            <p><b class="text-blue-dark">{{detail ? 'Radicado':'Señor'}}</b></p>
            <p v-if="radicado">{{radicado}}</p>
            <p v-if="name">{{name}}</p>
        </div>
        <span class="block w-3/4 h-px sm:w-1 sm:h-16 bg-blue-dark"></span>
        <div class="w-full py-2">
            <p><b class="text-blue-dark">{{detail ? 'Estado':'Email'}}</b></p>
            <p v-if="email">{{email}}</p>
            <ComplaintStatusLabel v-if="status" :status="status" danger="true" />
        </div>
    </div>
</template>

<script>
import ComplaintStatusLabel from '@/components/Customer/Complaint/ComplaintStatusLabel'
export default {
    props: ['name', 'radicado', 'detail', 'status', 'email'],
    components: {
        ComplaintStatusLabel
    }
}
</script>