<template>
  <div class="w-full mb-4 bg-white rounded-xl shadow-lg text-gray-600">
    <div
      class="px-6 py-2 rounded-lg flex justify-between items-center cursor-pointer p-3"
      @click="toggleAccordion"
      style="border-bottom: gray solid 1px"
    >
      <p class="text-dark text-left"><b>{{title_accordion}} {{extra}}</b></p>
      <span
        class="h-5 w-5 m-2 flex justify-center items-center transition"
        :class="{ 'transform rotate-180': accordionOpen }"
      >
        <img src="@/assets/icons/arrow-down.svg" alt="" />
      </span>
    </div>
    <div v-if="accordionOpen" class="py-3 px-6 pb-6">
      <div v-if="loading" class="flex justify-center items-center">
        <a-spin>
          <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
        </a-spin>
      </div>
      <div 
        class="flex flex-col gap-2"
        v-if="count > 0 || loading"
      >
        <div class="w-full grid grid-cols-1 gap-2">
          <BaseDownload
            v-for="(option, index) in attachments"
            label="Anexo de la respuesta final"
            :value="option.name_file"
            :key="index"
            :url_download="option.id_storage + separator"
          />
        </div>
        <div class="p-4 flex justify-end"
        v-if="count >= 10"><!-- 10: minimum number of attachments per page -->
          <a-pagination
            v-model="page"
            :page-size="pageSize"
            :page-size-options="pageSizeOptions"
            :total="count"
            show-size-changer
            size="small"
            @showSizeChange="onShowSizeChange"
          />
        </div>
      </div>
      <div v-else class="text-gray-400 mt-2">No hay archivos adjuntos</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    complaint: {
      type: Object,
      default: () => {return {};}
    },
    extra: {
      type: String,
      default: ''
    },
    references: {
      type: String,
      default: ''
    },
    title_accordion: {
      type: String,
      default: 'Anexos de la queja'
    }
  },
 
  data() {
    return {
      count: 0,
      page: 1,
      pageSize: 10,
      pageSizeOptions: ["10", "20", "50"],
      accordionOpen: false,
      attachments: [], 
      loading: true,
      pre_search: false,
      separator: '_id_storage__SFC',
      created_at: null
    };
  },
  created(){
    if(Object.keys(this.complaint).includes("created_at")){
      this.created_at = this.complaint.created_at;
    }
  },
  watch: {
    page: function (newVal, oldVal) {
      this.fetchAnnexes();
    },
  },
  methods: {
    toggleAccordion() {
      if (!this.pre_search){
        this.fetchAnnexes()
        this.pre_search = !this.pre_search;
      }
      this.accordionOpen = !this.accordionOpen;
    },
    async fetchAnnexes(){
      this.loading = true;
      this.attachments = [];

      let query = `?page_size=${this.pageSize}&page=${this.page}&codigo_queja=${this.complaint.codigo_queja}&reference_storage=${this.references}`;
      
      if(this.created_at){
        query += `&created_at__lte=${this.created_at}&get_from_bq=${true}`
      }

      let { error, data } = await this.$api.getAttachmentsAll(query);
      

      if (error) this.legend = "Ocurrió un error con la consulta";
      
      if (data) {
        this.attachments = data.results;
        this.count = data.total;
      }

      // this.count = this.attachments.length
      this.loading = false;
    },
    onShowSizeChange(page, page_size) {
      this.page = page;
      this.pageSize = page_size;
      this.fetchAnnexes()
    },
  },
};
</script>